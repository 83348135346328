import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StoreUtilService {

  constructor(private store: Store) { }

  public getSnapshot<T>(selector): Readonly<T> {
    let data: T;
    this.store.select<T>(selector).pipe(take(1)).subscribe(d => data = d);
    return data;
  }

}
