import { TopicsState } from '.';
import { createSelector, createFeatureSelector, props } from '@ngrx/store';
import { selectAll, selectIds, selectEntities, selectTotal } from './topics.reducer';

const featureTopic = createFeatureSelector<TopicsState>("topics");

export const getTopics = createSelector(featureTopic,
  (state: TopicsState) => state.entities);

export const getIsLoading = createSelector(featureTopic,
  (state: TopicsState) => state.isLoading);

export const getIsLoaded = createSelector(featureTopic, (state: TopicsState) => state.isLoaded);

export const selectAllTopics = createSelector(featureTopic, selectAll);
export const selectIdsTopics = createSelector(featureTopic, selectIds);
export const selectEntitiesTopics = createSelector(featureTopic, selectEntities);
export const selectTotalTopics = createSelector(featureTopic, selectTotal);

export const getTopicById = (id: string) => createSelector(featureTopic, (state) => state.entities[id]);
