import { createAction, props } from '@ngrx/store';

import { EnglishPhrase, ProgressWordEvaluation } from '../../classes';
// TODO no esta completo todavía hay actiones que solo pertenecen a palabras depurar aquí

export const setInitialState = createAction('[Phrases] set initial state');

export const loadPhrases = createAction('[Phrases] load phrases');

export const setIsLoading = createAction('[Phrases] set is loading', props<{ isloading: boolean }>());

export const loadPhrasesSuccess = createAction('[Phrases] load phrases success', props<{ phrases: Array<EnglishPhrase> }>());

export const createPhraseSuccess = createAction('[Phrases] create phrase success', props<{ phrase: EnglishPhrase }>());

export const updatePhraseSuccess = createAction('[Phrases] update phrase success', props<{ phrase: EnglishPhrase }>());

export const deletePhraseSuccess = createAction('[Phrases] delete phrase sucess', props<{ id: string }>());

export const updateProgressPhrase = createAction(
  '[Phrases] update progress phrase success',
  props<{ progress: Array<ProgressWordEvaluation> }>(),
);
