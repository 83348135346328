import { createReducer, on } from '@ngrx/store';

import { TopicsActions, TopicsState } from '.';

import { EntityAdapter, createEntityAdapter, Update, EntityMapOne } from '@ngrx/entity';

import { Topic } from '../../classes';

export const adapter: EntityAdapter<Topic> = createEntityAdapter<Topic>();

export const topicsInitialState: TopicsState = adapter.getInitialState({
  error: null,
  isLoading: true,
  isLoaded: false,
});

export const topicsReducer = createReducer(
  topicsInitialState,

  on(TopicsActions.setIsLoading, (state, action) => ({ ...state, isLoading: action.isloading })),

  on(TopicsActions.loadTopicsSuccess, (state, action): TopicsState => {
    state = { ...state, isLoaded: true, isLoading: false };
    return adapter.setAll(action.topics, state);
  }),

  on(TopicsActions.upsertTopic, (state, action): TopicsState => {
    const data = adapter.upsertOne(action.topic, state);
    return data;
  }),

  on(TopicsActions.insertWordInTopic, (state, action): TopicsState => {
    // La actualización requiere solo agregar una palabra al arreglo, esto no parece posible con entity ngrx por lo que se hace manual
    const id: string = action.topicId;
    const words = [...state.entities[id].words, action.word];
    const update: Update<Topic> = { id: id, changes: { words } };
    return adapter.updateOne(update, state);
  }),

  on(TopicsActions.insertVerbInTopic, (state, action): TopicsState => {
    const id: string = action.topicId;
    const verbs = [...state.entities[id].verbs, action.verb];
    const update: Update<Topic> = { id: id, changes: { verbs } };
    return adapter.updateOne(update, state);
  }),

  on(TopicsActions.deleteTopicSuccess, (state, action): TopicsState => {
    return adapter.removeOne(action.id, state);
  }),

  on(TopicsActions.deleteWordFromTopic, (state, action): TopicsState => {
    const id: string = action.topicId;
    const filterWords = state.entities[id].words.filter((word) => word.word !== action.word);
    const update: Update<Topic> = { id, changes: { words: filterWords } };
    return adapter.updateOne(update, state);
  }),

  on(TopicsActions.addTopicSucess, (state, action): TopicsState => {
    return adapter.addOne(action.topic, state);
  }),
);

adapter.getSelectors();

export const {
  selectIds, // Selecciona los ids
  selectEntities, // Seleccionar el diccionario
  selectAll, // Selecciona solo el arreglo
  selectTotal, // hace un conteo de cuantos datos existen
} = adapter.getSelectors();
