import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, take, tap, filter } from 'rxjs/operators';

import { VerbService } from '../../data-services/verb.service';
import { VerbActions, VerbSelectors } from '.';

import { StoreUtilService } from './../../../core/store/store-util.service';

@Injectable()
export class VerbsEffects {

  constructor(private storeUtilService: StoreUtilService, private store: Store, private actions$: Actions, private verbService: VerbService) {
  }

  loadProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VerbActions.loadVerbs),
      filter(() => !this.isLoaded()),
      mergeMap(() => this.verbService.getVerbs$().pipe(
        map(verbs => VerbActions.loadVerbsSuccess({ verbs })),
      )
      )
    )
  });

  private isLoaded(): boolean {
    return this.storeUtilService.getSnapshot<boolean>(VerbSelectors.getIsLoaded);
  }

}
