import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { PhraseActions, PhrasesState } from '.';
import { EnglishPhrase } from '../../classes';

// TODO depurar aquí el código que no sirve, la mayoría viene de words ya que se usó como base
export const wordInitialState: PhrasesState = { phrases: null, error: null, isLoading: true, isLoaded: false };

export const adapter: EntityAdapter<EnglishPhrase> = createEntityAdapter<EnglishPhrase>();

export const phraseInitialState: PhrasesState = adapter.getInitialState({
  error: null,
  isLoading: true,
  isLoaded: false,
  phrases: null,
});

export const phrasesReducer = createReducer(
  phraseInitialState,

  on(PhraseActions.setInitialState, () => wordInitialState),

  on(PhraseActions.setIsLoading, (state, action) => ({ ...state, isLoading: action.isloading })),

  on(PhraseActions.loadPhrasesSuccess, (state, action): PhrasesState => {
    return { ...state, phrases: [...action.phrases], isLoading: false, isLoaded: true };
  }),

  on(PhraseActions.createPhraseSuccess, (state, action): PhrasesState => {
    return { ...state, phrases: [...state.phrases, action.phrase] };
  }),

  on(PhraseActions.updatePhraseSuccess, (state, action): PhrasesState => {
    const updatedItems = state.phrases.map((item) => (action.phrase.id === item.id ? action.phrase : item));
    return { ...state, phrases: updatedItems, error: '' };
  }),

  on(PhraseActions.deletePhraseSuccess, (state, action): PhrasesState => {
    const filterPhrases = state.phrases.filter((phrase) => phrase.id !== action.id);
    return { ...state, phrases: filterPhrases, error: '' };
  }),

  on(PhraseActions.updateProgressPhrase, (state, action): PhrasesState => {
    const phrasesMapped = state.phrases.map((phrase) => {
      // TODO: Necesitaba id en el progreso? es porque una frase no es unica, debe guardarse por id.
      for (let p of action.progress) {
        // if (p.id === phrase.id) {
        //   return { ...phrase, progress: p.progress }
        // }
      }
      return phrase;
    });
    return { ...state, phrases: phrasesMapped, error: '' };
  }),
);
