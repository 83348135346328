import { Component, OnInit, Input, TemplateRef, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormArray, FormBuilder, FormGroup, FormArray, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

import { NGXLogger } from 'ngx-logger';

import { LangWord } from '../../../core/classes';
import { WordService } from '../../../core/data-services/word.service';
import { TopicService } from '../../../core/data-services/topic.service';
import { Tenses, VoiceTypes, WordFormMode, scoreOptions, useFrequencyOptions, wordTypeOptions } from 'src/app/core/enums';
import { AudioService } from 'src/app/core/system/audio.service';
import { MultiImagesStorageService } from 'src/app/shared/app-cropper/multi-images-storage.service';

import { NgIf, NgFor } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { ToastService } from 'src/app/core/system/toast.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AdminService } from 'src/app/core/data-services/admin.service';
import { AiService } from 'src/app/core/data-services/ai.service';
import { LearningExampleFormComponent, LearningForm } from './learning-example-form/learning-example-form.component';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from 'src/app/core/data-services/user.service';

@Component({
  selector: 'app-word-form',
  templateUrl: './word-form.component.html',
  styleUrls: ['./word-form.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    LearningExampleFormComponent,
  ],
})
export class WordFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() langWord: LangWord = new LangWord();
  @Input() topicId: string = null; // Cuando el formulario lo activa un topic id inicia desde aquí
  @Input() mode: WordFormMode; // admin, suggestion, regular,

  //  TODO: quite score todavía lo necesito ,creo que lo renombré a progreso.

  public formGroup = this.formBuilder.group({
    word: ['', Validators.required],
    translation: [''],
    useFrequency: [1],
    type: [''],
    examples: this.formBuilder.array<FormGroup<any>>([]),
    synonyms: this.formBuilder.array<FormGroup<any>>([]),
    definitions: this.formBuilder.array<FormGroup<any>>([]),
    otherTranslations: this.formBuilder.array<FormGroup<any>>([]),
    learningExamples: this.formBuilder.array<LearningForm>([]),
    en: this.formBuilder.group({}),
    es: this.formBuilder.group({}),
  });

  public loadingWord = false;
  public isSaving = false;
  public buttonText = 'Guardar Palabra';

  public tenses = Tenses;
  public voices = VoiceTypes;
  public modalRef;

  public scoreOptions = scoreOptions;
  public useFrequencyOptions = useFrequencyOptions;
  public wordTypeOptions = wordTypeOptions;

  private destroy$ = new Subject<void>();

  constructor(
    private topicService: TopicService,
    private wordService: WordService,
    private logger: NGXLogger,
    private toastrService: ToastService,
    private audioService: AudioService,
    private multiImagesStorageService: MultiImagesStorageService,
    protected formBuilder: FormBuilder,
    private adminService: AdminService,
    private aiService: AiService,
    private dynamicDialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private userService: UserService,
  ) {
    if (this.dynamicDialogConfig.data) {
      this.langWord = this.dynamicDialogConfig.data.englishWord;
      this.mode = this.dynamicDialogConfig.data.mode;
    }
  }

  public ngOnInit(): void {
    const base = this.userService.getUserSnapshot().settings.baseLanguage;

    if (this.langWord[base]) {
      this.langWord = { ...this.langWord, ...this.langWord[base] };
      console.log('word', this.langWord);
    }

    if (this.mode === WordFormMode.Admin) {
      this.formGroup.addControl('learningExamples', new FormArray([]));

      if (this.langWord.learningExamples) {
        this.langWord.learningExamples.forEach((_) => {
          this.addLearningExampleForm();
        });
      }

      // if (this.englishWord[base]){
      //   const translations = this.englishWord[base];
      //   translations.translation
      // }
    }

    if (this.langWord != null) {
      this.setFormData(this.langWord);
    }
  }

  ngAfterViewInit(): void {
    // No puedo usar takeUntilDestroyed porque hay que hacerlo en el contructor, es mucho problema injectar referencia, y detecto changes after view init, por eso a la antigua.
    const controlNames = ['examples', 'synonyms', 'definitions', 'otherTranslations'];

    controlNames.forEach((controlName) => {
      this.formGroup.controls[controlName].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.formGroup.controls[controlName].markAsDirty();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get otherTranslationsFormArray(): FormArray {
    return this.formGroup.controls.otherTranslations;
  }
  get synonymsFormArray() {
    return this.formGroup.controls.synonyms;
  }

  get definitionsFormArray() {
    return this.formGroup.controls.definitions;
  }

  get exampleFormArray() {
    return this.formGroup.controls.examples;
  }

  private getChangedValues(): any {
    const modifiedValues = {};

    Object.keys(this.formGroup.controls).forEach((controlName) => {
      const control = this.formGroup.get(controlName);
      if (control.dirty) {
        if (controlName === 'learningExamples') {
          this.toastrService.info('Asegúrate de guardar los ejemplos de aprendizaje', 'Los ejemplos se guardan por separado');
          return;
        }
        modifiedValues[controlName] = control.value;
      }
    });

    if (Object.keys(modifiedValues).length === 0) {
      this.toastrService.info('No se ha modificado la palabra', 'No hay cambios');
      return null;
    }
    return modifiedValues;
  }

  public async save(): Promise<void> {
    const wordForm: any = this.formGroup.getRawValue();

    const wordFormChanged = this.getChangedValues();
    if (!wordFormChanged) {
      return;
    }

    if (this.langWord) {
      this.logger.debug('editando', this.langWord);
      wordFormChanged.word = this.langWord.word;
    }
    let response = null;

    try {
      this.isSaving = true;
      if (this.mode === WordFormMode.Suggestion) {
        response = this.wordService.promoteWord(wordForm);
      } else if (this.topicId) {
        response = await this.topicService.saveTopicWord(wordForm, this.topicId);
      } else if (this.mode === WordFormMode.Admin) {
        if ('translation' in wordFormChanged || 'otherTranslations' in wordFormChanged) {
          const base = this.userService.getUserSnapshot().settings.baseLanguage;
          wordFormChanged[base] = { translation: this.langWord.translation, otherTranslations: this.langWord.otherTranslations };
          if (wordFormChanged.translation) {
            wordFormChanged[base].translation = wordFormChanged.translation;
            delete wordFormChanged.translation;
          }
          if (wordFormChanged.otherTranslations) {
            wordFormChanged[base].otherTranslations = wordFormChanged.otherTranslations;
            delete wordFormChanged.otherTranslations;
          }
        }

        response = await this.adminService.postWord(wordFormChanged);
        this.toastrService.success(`Se guardó la palabra ${this.langWord.word}`, 'Guardado');
      }
    } catch (err) {
    } finally {
      this.isSaving = false;
      this.dismiss(response);
    }
  }

  // TODO: de momento no sirve lo de promocionar. borrar si  no lo ocupo
  // public promoteWord(): void {
  //   const isConfirmed = confirm(
  //     'Se tomarán a consideración la información del verbo para actualizar la base de datos de Polilan, ¿Estas seguro de que la información es correcta? ',
  //   );
  //   if (!isConfirmed) {
  //     return;
  //   }

  //   this.wordService.promoteWord$(this.englishWord.id).subscribe(
  //     () => { },
  //     (err) => { },
  //     () => {
  //       this.toastrService.success('La palabra se agregará pronto a la base de datos', 'Muchas Gracias');
  //     },
  //   );
  // }

  public pushControlToFormArray(controlName: string): void {
    (this.formGroup.get(controlName) as UntypedFormArray).push(this.formBuilder.control(''));
  }

  private addEmptyArrayControl(englishWord: LangWord, property: string): void {
    if (englishWord[property] && Array.isArray(englishWord[property])) {
      englishWord[property].forEach((_) => {
        this.pushControlToFormArray(property);
      });
    } else {
      englishWord[property] = [];
    }
  }

  public deleteLearningExample(index: number): void {
    const confirm = window.confirm('¿Estás seguro de que quieres eliminar este ejemplo de aprendizaje?');
    if (!confirm) {
      return;
    }

    this.deleteFormArrayByIndex('learningExamples', index);
  }

  public deleteFormArrayByIndex(controlName: string, index: number): void {
    // sería ideal que los learning examples se eliminen uno por uno, asi puedo borrar la imagen en el storage, si no se sobreescribe el objeto y se queda atrapada.
    console.log('Eliminado del control', controlName, index);
    (this.formGroup.get(controlName) as UntypedFormArray).removeAt(index);
  }

  public setFormData(dataObj: any): void {
    const data = { ...dataObj };

    this.addEmptyArrayControl(data, 'examples');
    this.addEmptyArrayControl(data, 'synonyms');
    this.addEmptyArrayControl(data, 'definitions');
    this.addEmptyArrayControl(data, 'otherTranslations');

    this.formGroup.patchValue(data);
  }

  protected dismiss(response): void {
    this.ref.close(response);
  }

  get learningExamplesForm() {
    return this.formGroup.controls.learningExamples;
  }

  public addLearningExampleForm(data?: any): void {
    // Agrega un nuevo form, vacio o con data
    const learningForm = this.formBuilder.group({
      id: [data?.id || ''],
      example: [data?.example || ''],
      meaning: [data?.meaning || ''],
      scenario: [data?.scenario || ''],
      image: [data?.image || ''],
      tense: [data?.tense || ''],
      attribution: [data?.attribution || ''],
      audio: [data?.audio || ''],
      voiceType: [data?.voiceType || ''],
      difficultyLevel: [data?.difficultyLevel || ''],
      isPublic: [data?.isPublic || false],
      es: this.formBuilder.group({
        translation: [data?.translation || ''],
        meaningTranslation: [data?.spanishExplanation || ''],
      }),

      en: this.formBuilder.group({
        translation: [data?.translation || ''],
        meaningTranslation: [data?.spanishExplanation || ''],
      }),
    });

    this.learningExamplesForm.push(learningForm);
  }

  public playText(audio: any): void {
    if (audio.path) {
      this.audioService.playAudioWithStoragePath(audio.path);
    }
  }

  public openCropper(dialog: TemplateRef<any>): void {
    // this.modalRef = this.dialogService.open(dialog, {
    //   data: 'this is some additional data passed to dialog',
    //   styleClass: 'max-width-900-wform',
    // });
  }

  public async uploadImage(imageBlob: Blob): Promise<void> {
    const path = `words/${this.langWord.word}/images`;

    const imageUploaded = await this.multiImagesStorageService.uploadImage(imageBlob, path);
    this.modalRef.close();
    console.log(imageUploaded);
  }

  public async generateExample(): Promise<void> {
    const isConfirm = confirm(
      'Se generará un ejemplo nuevo completamente aleatorio, si quieres dar la idea crea primero el ejemplo y autompleta con ai',
    );
    if (!isConfirm) {
      return;
    }
    const word = this.formGroup.get('word').value;
    const example = await this.aiService.generateLearningExample(word);
    this.addLearningExampleForm(example);
    console.log(example);
  }

  public addEmptyExample(): void {
    this.addLearningExampleForm();

    setTimeout(() => {
      this.scrollToBottom('p-dialog-content');
    }, 250);
  }

  private scrollToBottom(elementId) {
    const element = document.getElementsByClassName(elementId)[0];
    if (!element) {
      return;
    }
    element.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth',
    });
  }
}
