import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { EnglishVerb, DeletedData, Topic, LangWord } from '../classes';
import { HttpService } from '../system/http.service';
import { TopicsActions, TopicsSelectors } from '../store/topics';
import { TopicWebApi } from '../enums';
import { StoreUtilService } from '../store/store-util.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class TopicService {
  constructor(
    private userService: UserService,
    private storeUtilService: StoreUtilService,
    private store: Store,
    private httpService: HttpService,
  ) {}

  public async saveTopic(topic: Topic): Promise<Topic> {
    const path = `topic`;
    const user = this.userService.getUserSnapshot();
    topic.userIdAuthor = user.id;
    topic.userAuthor = user.personalData.firstname ?? 'usuario';
    const topicResponse: Topic = await this.httpService.postDataToService(path, topic);
    this.store.dispatch(TopicsActions.addTopicSucess({ topic: topicResponse }));
    return topicResponse;
  }

  public getTopics$(): Observable<Array<Topic>> {
    return this.httpService.getObservable<Array<Topic>>(TopicWebApi.getTopics);
  }

  public async loadTopics() {
    const topics = await this.httpService.getDataFromService(TopicWebApi.getTopics);
    this.store.dispatch(TopicsActions.loadTopicsSuccess({ topics }));
  }

  public async deleteTopic(id: string) {
    const path = `topic/${id}`;
    const response = await this.httpService.deleteDataFromService(path);
    this.store.dispatch(TopicsActions.deleteTopicSuccess({ id }));
    return response;
  }

  public removeWordFormTopic$(topicId: string, word: string): Observable<any> {
    const path = `${TopicWebApi.deleteWordFromTopic}/${topicId}/${word}`;

    return this.httpService.deleteObservable(path).pipe(
      switchMap((data) => {
        this.store.dispatch(TopicsActions.deleteWordFromTopic({ topicId, word }));
        return of(data);
      }),
    );
  }

  public dispatchNewWordOfTopic(topicId: string, word: LangWord) {
    this.store.dispatch(TopicsActions.insertWordInTopic({ topicId, word }));
  }

  public dispatchNewVerbOfTopic(topicId: string, verb: EnglishVerb) {
    this.store.dispatch(TopicsActions.insertVerbInTopic({ topicId, verb }));
  }

  public async saveWordInTopic(word: string, topicId: string) {
    const wordSaved = await this.httpService.postDataToService(`${TopicWebApi.PostWordIntoTopic}/${topicId}`, { word });
    return wordSaved;
  }

  public async getTopicFromId(id: string) {
    const path = `topic/${id}`;
    console.log('Buscasndo tema con path', path);
    return await this.httpService.getDataFromService(path);
  }

  public async getWordsFromTopic(topicId: string) {
    const path = `topic/${topicId}/words`;
    return await this.httpService.getDataFromService(path);
  }

  public getWordsOfTopic$(topicId: string) {
    return this.httpService.getObservable<LangWord[]>(`${TopicWebApi.getWordsOfTopic}/${topicId}`);
  }

  public getFullTopic$(topicId: string) {
    const servicePath = `${TopicWebApi.getTopic}/${topicId}?complete=true`;
    return this.httpService.getObservable<Topic>(servicePath);
  }

  public async saveTopicWord(word, topicId) {
    const path = `topic/word/${topicId}`;
    return await this.httpService.postDataToService(word, path);
  }

  public async saveTopicVerb(verb: string, topicId: string) {
    const path = `topic/verb/${topicId}`;
    return await this.httpService.postDataToService(path, { body: verb });
  }

  public async saveCustomTopicVerb(verb: EnglishVerb, topicId: string) {
    const path = `topic/customVerb/${topicId}`;
    return await this.httpService.postDataToService(path, verb);
  }

  public getTopicSnapshot(topicId: string) {
    return this.storeUtilService.getSnapshot<Topic>(TopicsSelectors.getTopicById(topicId));
  }
}
