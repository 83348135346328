import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, take, tap, filter } from 'rxjs/operators';

import { PhraseService } from '../../data-services/phrase.service';
import { StoreUtilService } from '../store-util.service';

import { PhraseActions, PhraseSelectors } from '.';


@Injectable()
export class PhrasesEffects {

  constructor(private storeUtilService: StoreUtilService, private actions$: Actions, private phraseService: PhraseService) {
  }

  loadPhrases$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PhraseActions.loadPhrases),
      filter(() => !this.isLoaded()),
      mergeMap(() => this.phraseService.getPhrases$().pipe(
        map(phrases => PhraseActions.loadPhrasesSuccess({ phrases })),
      )
      )
    )
  });

  private isLoaded(): boolean {
    const isloaded = this.storeUtilService.getSnapshot<boolean>(PhraseSelectors.getIsLoaded);
    return isloaded;
  }

}
