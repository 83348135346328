import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SalesLetterComponent } from 'src/app/information/componentSalesLetter/sales-letter.component';

@Injectable({
  providedIn: 'root',
})
export class SalesLetterService {
  constructor(private dialogService: DialogService) {}

  public openSalesLetterDialog(): void {
    this.dialogService.open(SalesLetterComponent, {
      header: 'Promoción final y regalo',
      width: '95%',
      // styleClass: 'dialog-learning-example',
      focusOnShow: false,
    });
  }
}
