<div class="form-input-card">
  <div>{{ title }}</div>
  <div [innerHTML]="message">

    <br />
    <div class="subtext" *ngIf="subtext">
      {{ subtext }}
    </div>
  </div>

  <div>
    <div style="float: right">
      <p-button *ngIf="oneBotton" label="Ok" (click)="confirm()"></p-button>

    </div>

    <div *ngIf="!oneBotton" class="options">
      <p-button [label]="cancelText" (click)="cancel()" severity="secondary"></p-button>
      <p-button [label]="acceptText" (click)="confirm()" severity="danger"></p-button>
    </div>
  </div>
</div>