import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { UserService } from 'src/app/core/data-services/user.service';
import { StripeService } from 'src/app/core/stripe.service';
import { PaymentsService } from 'src/app/core/system/payments.service';

@Component({
  selector: 'app-component-sales-letter',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './sales-letter.component.html',
  styleUrl: './sales-letter.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesLetterComponent implements OnInit {
  public constructor(private userService: UserService, private stripeService: StripeService, private paymentsService: PaymentsService) {}

  async ngOnInit() {}

  isLoadingStripe = false;

  public async payStripe(months: number) {
    const user = this.userService.getUserSnapshot();

    if (!user) {
      alert('Inicia sesión y ve a subscripción para acceder a esta promoción');
      return;
    }

    if (this.userService.isPremium()) {
      alert('Ya tienes una suscripción Premium activa');
    } else {
      try {
        this.isLoadingStripe = true;
        await this.stripeService.createSessionPremiumMonths(months);
      } finally {
        this.isLoadingStripe = false;
      }
    }
  }
}
