import { Injectable } from '@angular/core';
import { HttpService } from './system/http.service';
import { PaymentApi } from './enums';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(private httpService: HttpService) {}

  async createSessionPremiumMonths(months) {
    const response = await this.httpService.postDataToService(PaymentApi.CreateCheckoutSession, { months: months });
    if (response.sessionUrl) {
      window.location.href = response['sessionUrl'];
    } else {
      console.error('POST no puede contactar el sistema de pagos error', response);
    }
  }

  async updateUserSubscriptionIfPaid(sessionId: string) {
    const response = await this.httpService.getDataFromService(`${PaymentApi.ApplySusbcription}/${sessionId}`);
    return response;
  }
}
