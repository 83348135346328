import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class IsMobileGuardService {
  constructor(private router: Router) {}

  public canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): any {
    if (Capacitor.isNativePlatform()) {
      return this.router.parseUrl('m/welcome');
    } else {
      return true;
    }
  }
}
