import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-welcome-follower-confirm',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './welcome-follower-confirm.component.html',
  styleUrl: './welcome-follower-confirm.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeFollowerConfirmComponent {
  constructor(public ref: DynamicDialogRef) {}

  public toSocialNetwork(social: string) {
    let url;
    if (social === 'instagram') {
      url = 'https://www.instagram.com/polilan_app';
    } else if (social === 'tiktok') {
      url = 'https://www.tiktok.com/@polilan_app';
    } else if (social === 'youtube') {
      url = 'https://www.youtube.com/@polilan_app';
    }

    window.open(url, '_blank');
  }

  public openSalesLetter() {
    this.ref.close('sales-letter');
  }
}
