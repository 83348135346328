import { createAction, props } from '@ngrx/store';

import { EnglishVerb, LangWord } from './../../classes';
import { Topic } from '../../classes';

export const setInitialState = createAction('[Topic] set initial state');

export const setIsLoading = createAction('[Topic] set is loading', props<{ isloading: boolean }>());

export const loadTopics = createAction('[Topic] load topics');

export const loadTopicsSuccess = createAction('[Topic] load topics success', props<{ topics: Array<Topic> }>());

export const deleteTopicSuccess = createAction('[Topic] delete topics sucess', props<{ id: string }>());

export const deleteWordFromTopic = createAction('[Topic] delete word from topic', props<{ topicId: string; word: string }>());

export const addTopicSucess = createAction('[Topic] add topic sucess', props<{ topic: Topic }>());

export const loadWordsOfTopic = createAction('[Topic] load word of topic', props<{ topicId: string }>());

export const loadFullTopicWithId = createAction('[Topic] load full topic', props<{ topicId: string }>());

export const upsertTopic = createAction('[Topic] update topic', props<{ topic: Topic }>());

export const updateTopicWords = createAction('[Topic] update topic words', props<{ words: LangWord[] }>());

export const insertWordInTopic = createAction('[Topic] insert word in topic', props<{ topicId: string; word: LangWord }>());

export const insertVerbInTopic = createAction('[Topic] insert verb in topic', props<{ topicId: string; verb: EnglishVerb }>());
