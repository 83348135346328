import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, take, tap, filter } from 'rxjs/operators';

import { WordService } from '../../data-services/word.service';
import { StoreUtilService } from './../../../core/store/store-util.service';

import { WordActions, WordSelectors } from '.';


@Injectable()
export class WordsEffects {

  constructor(private storeUtilService: StoreUtilService, private actions$: Actions, private wordService: WordService) {
  }
  // some how this variable loadWords$ does not matter, internaly createEffect add the effects to the root effects

  loadWords$ = createEffect(() => {
    // para reflexionar, loadProduct, se ejecuta primer dispatch, El reducer es quien escucha las acciones, pero no esta allí.
    // pero también hay que entender que this.actions$ recibe todas las acciones que estan siendo dispatch  aqui hacemos el filtro adecuando
    return this.actions$.pipe(
      ofType(WordActions.loadWords),
      filter(() => !this.isLoaded()),
      mergeMap(() => this.wordService.getWords$().pipe(
        // TODO: comentado hasta entender, el estado inicial de isLoading siempre será true,
        // tap(() => this.store.dispatch(WordActions.setIsLoading({ isloading: true }))),
        map(words => WordActions.loadWordsSuccess({ words })),
      )
      )
    )
  });

  private isLoaded(): boolean {
    const isloaded = this.storeUtilService.getSnapshot<boolean>(WordSelectors.getIsLoaded);
    return isloaded;
  }

}
