<h2>🎉 Una Oportunidad Única en la Vida</h2>

<p>Estimado aprendiz de idiomas:</p>

<p
  >Polilan esta en <b>pre-lanzamiento</b> y ofreceremos la mejor oferta que
  <b> jamás haremos</b>
  para primeros usuarios antes de
  <b>3/Noviembre/2024</b>
</p>

<p
  >Te recomendamos ver el video para entender, como <b> Polilan sobrepasa </b>otras apps y metodologías de aprendizaje de inglés y otros idiomas
</p>

<iframe
  width="100%"
  height="280"
  src="https://www.youtube.com/embed/no2S_jW__VI?si=_HEYW_8Aaasb-YPS"
  title="YouTube video player"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  referrerpolicy="strict-origin-when-cross-origin"
  allowfullscreen></iframe>

<h3>🌟 Lo que obtienes con esta oferta:</h3>

<h3>Tres años de acceso a la plataforma, tiempo más que suficiente para que no tengas que comprar ninguna otra app</h3>

<h3>1) Lo que ya tienes pero ilimitado</h3>
<ul>
  <li>Guarda todas las palabras que necesites</li>
  <li>Mira y practica con todos los ejemplos</li>
</ul>

<h2>2) Acceso a las funcionalidades premium </h2>

<ul>
  <li>Acceso a las lecciones</li>
  <li>Acceso a los escenarios</li>
  <li>Conversaciones</li>
  <li>Recomendaciones de la AI para identificar contenido que te hará mejorar</li>
</ul>

<p>
  <b>Lo que tendrás acceso próximamente</b>
</p>

En 3 años la tecnología avanzará bastante, y también nuestros métodos y funcionalidades, Es un tiempo más que suficiente para que tú hables
inglés o cualquier otro idioma que te propongas, serás testigo de esta revolución sin volver a pagar.

<h3>🆕 Próximas características y funcionalidades:</h3>

<ul>
  <li>Acceso desde Android Dic/2024</li>
  <li>Acceso desde iOS (iphone,ipad) Ene/2025</li>
  <li>Acceso a mejora tecnológica y nuevas funcionalidades</li>
  <li>Conjugador de Verbos</li>
  <li>Vocalizador</li>
  <li>Nuevos idiomas: Alemán, Japonés, Árabe entre otros</li>
  <li>Integración de nuevos componentes</li>
</ul>

<h3>🌈 Tu Transformación:</h3>

<ul>
  <li>Más confianza al hablar, ya no habrá: lo escucho pero no lo hablo </li>
  <li>Es un hecho indiscutible que brinda oportunidades con salarios más altos</li>
  <li>Viajar al extranjero y comunicarte con las personas</li>
  <li>Es una satisfacción el saber que aprendes algo y conversas por fin </li>
</ul>

<h2>🔥 No dejes pasar esta oportunidad única ⏰ Actúa Ahora</h2>

<p>Invierte en tu futuro hoy, con Polilan, no solo es un idioma es una habilidad para toda la vida.</p>

<p>Esta oferta termina:</p>
<ul>
  <li>Al finalizar Octubre de 2024</li>
  <li>O cuando alcancemos 500 usuarios premium</li>
</ul>

<h3>💰 Inversión Incomparable:</h3>

<p><strong class="highlight">Solo $127 dólares (USD) por 3 años completos</strong></p>

<p><em>Compara:</em></p>
<ul>
  <li><span style="text-decoration: line-through">Precio prelanzamiento anual: $89 USD /año</span></li>
  <li>Precio final anual: $197 USD /año </li>
  <li>Tu ahorro: Más de $464 USD en 3 años</li>
</ul>

<p><em>Recuerda: Esta oferta jamás se repetirá, y es hasta terminar cupo, ¡Asegura tu lugar en la revolución del aprendizaje de idiomas!</em></p>

<div style="display: flex; flex-direction: column">
  <p-button (click)="payStripe(36)" [disabled]="isLoadingStripe" label="Obtener 3 años con la mejor Promoción" />
</div>
