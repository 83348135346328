import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';

import { FirebaseAuthService } from './firebase-auth.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorTokenService implements HttpInterceptor {
  constructor(private fbAuthService: FirebaseAuthService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // obtengo el id directo de la biblioteca de firebase, en un observable procesado en tokenId$

    return this.fbAuthService.tokenId$.pipe(
      mergeMap((token) => {
        const modifiedRequest = req.clone({
          setHeaders: { Authorization: `Bearer ${token}` },
        });
        return next.handle(modifiedRequest);
      }),
    );
  }
}
