import { Injectable } from '@angular/core';
import { AIAPI } from '../enums';
import { HttpService } from '../system/http.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsDataService {
  constructor(private httpService: HttpService) {}

  public async getServerStatus(): Promise<any> {
    try {
      this.httpService.getDataFromService(`${AIAPI.ServerStatus}`, 'python', true);
      this.httpService.getDataFromService(`${AIAPI.ServerStatus}`, 'node', true);
    } catch (error) {
      console.error('Error en UtilsDataService.getServerStatus');
    }
  }
}
