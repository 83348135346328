import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LearningExample } from 'src/app/core/classes';
import { VoiceOptions } from 'src/app/core/constants';
import { LearningExamplesService } from 'src/app/core/data-services/learningExamples.service';

import { VoiceTypes } from 'src/app/core/enums';
import { ToastService } from 'src/app/core/system/toast.service';
import { AudioService } from 'src/app/core/system/audio.service';
import { CropperComponent } from 'src/app/shared/app-cropper/cropper/cropper.component';
import { MultiImagesStorageService } from 'src/app/shared/app-cropper/multi-images-storage.service';

export type LearningForm = FormGroup<{
  id: FormControl<any>;
  example: FormControl<any>;
  meaning: FormControl<any>;
  scenario: FormControl<any>;
  image: FormControl<any>;
  tense: FormControl<any>;
  attribution: FormControl<any>;
  audio: FormControl<any>;
  voiceType: FormControl<any>;
  difficultyLevel: FormControl<any>;
  isPublic: FormControl<any>;
  es: FormGroup<{ meaningTranslation: FormControl<any>; translation: FormControl<any> }>;
  en: FormGroup<{ meaningTranslation: FormControl<any>; translation: FormControl<any> }>;
}>;

@Component({
  selector: 'app-learning-example-form',
  standalone: true,
  imports: [
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    NgFor,
    ButtonModule,
    DialogModule,
    CropperComponent,
    CheckboxModule,
  ],
  templateUrl: './learning-example-form.component.html',
  styleUrl: './learning-example-form.component.scss',
})
export class LearningExampleFormComponent {
  @Input() form: LearningForm;
  @Input() word: string;
  @Output() removed = new EventEmitter<boolean>();
  public voiceOptions = VoiceOptions;

  public isLoading = false;
  public id = null;
  public isCropperVisible = false;

  constructor(
    private audioService: AudioService,
    private learningExamplesService: LearningExamplesService,
    private toastService: ToastService,
    private imageStorageService: MultiImagesStorageService,
  ) {}

  ngOnInit(): void {
    this.id = this.form.get('id').value;
  }

  public async saveLearningExample() {
    // TODO agregar la bander de is public, ya que por defual no se va a mostrar.
    const learningExample = this.form.getRawValue();
    try {
      const response = await this.learningExamplesService.postLearningExample(this.word, learningExample);
      this.toastService.success('Ejemplo de aprendizaje guardado');
      this.form.patchValue(response);
      if (response.id) {
        this.id = response.id;
      }
    } finally {
      this.isLoading = false;
    }
  }

  public async autoCompleteExample() {
    const learningExample = this.form.getRawValue() as unknown as LearningExample;
    const isConfirmed = confirm(
      `Se utilizará el texto para generar un ejemplo: \n "${learningExample.example}" \n(si hay datos podrían sobreescribirse)`,
    );
    if (!isConfirmed) {
      return;
    }

    try {
      this.isLoading = true;
      const le = await this.learningExamplesService.autogenerateLearningExample(this.word, learningExample);
      this.form.patchValue(le);
    } finally {
      this.isLoading = false;
    }
  }

  public async removeLearningExample() {
    const confirm = window.confirm('¿Estás seguro de que quieres eliminar este ejemplo de aprendizaje?');
    if (confirm) {
      try {
        await this.learningExamplesService.deleteLearningExample(this.word, this.id);
        this.toastService.success('Ejemplo de aprendizaje eliminado');
        this.removed.emit(true);
      } finally {
        this.isLoading = false;
      }
    }
  }

  public showImageCropper() {
    this.isCropperVisible = true;
  }

  public async onUploadedImage(imageStorage: any) {
    this.isCropperVisible = false;
    const currentImage = this.form.get('image').value;
    if (currentImage) {
      this.imageStorageService.deleteImage(currentImage.path);
      console.log('deberia eliminar la imagen anterior');
    }

    this.form.get('image').patchValue(imageStorage);

    const update = { id: this.id, image: imageStorage };
    const response = await this.learningExamplesService.postLearningExample(this.word, update);
    this.toastService.success('Se guardó la imagen');
  }

  public async generateAudio() {
    try {
      this.isLoading = true;
      const audio = await this.learningExamplesService.generateVoiceForLearningExample(this.word, this.id);
      this.form.get('audio').patchValue(audio);
    } finally {
      this.isLoading = false;
    }
  }

  public playAudio(audio: any) {
    this.audioService.playAudioWithStoragePath(audio.path);
  }

  public playVoiceExample(voiceId: string) {
    const voice = this.voiceOptions.find((v) => v.id === voiceId);
    if (voice && voice.exampleUrl) {
      this.audioService.playWithSrc(voice.exampleUrl);
    } else {
      alert('No existe un ejemplo de audio para esta voz');
    }
  }

  public generateImageAgain() {
    const isConfirmed = confirm('¿Volver a crear la imagen con AI?');
    if (!isConfirmed) {
      return;
    }
    alert('Esta funcionalidad esta pendiente por implementar');
  }
}
