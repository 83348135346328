import { Injectable } from '@angular/core';
import { ImageMultipleCrops, ImgStorageData } from './cropper/cropper.classes';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { getDownloadURL, getStorage, deleteObject, listAll } from '@angular/fire/storage';

import { ref } from 'firebase/storage';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MultiImagesStorageService {
  constructor(private storage: AngularFireStorage) { }

  public async uploadImage(image: Blob, path: string) {

    const refStorage = this.storage.ref(path);
    const task = await refStorage.put(image);
    const { fullPath, bucket } = task.metadata;
    const url = await lastValueFrom(refStorage.getDownloadURL());
    const imageStorage = { url, path: fullPath, bucket }
    return imageStorage;
  }

  public uploadImage2(imageMulticrops: ImageMultipleCrops, path: string): Promise<ImgStorageData> {
    // path is usually [products/id] will create a new folder with the name and upload the images

    const blobs = imageMulticrops.imagesBlobs;

    const tasks: Array<Promise<any>> = [];
    let fileName = imageMulticrops.settings?.renameFile ?? imageMulticrops.file.name;
    fileName = fileName.split('.')[0];

    // load the default image jpeg
    path = `${path}/${fileName}`;
    const defaultImagePath = `${path}/${fileName}-400W.jpeg`;
    const refStorage = this.storage.ref(defaultImagePath);
    const task = refStorage.put(imageMulticrops.defaultImageBlob);

    tasks.push(this.uploadAndGetUrl(task, 'default'));

    // Upload webp images
    for (const resolutionStr of Object.keys(blobs)) {
      const fullName = `${fileName}-${resolutionStr}W.webp`;
      const filePath = `${path}/${fullName}`;

      const refStorage = this.storage.ref(filePath);

      const resolution = Number(resolutionStr);
      const task = refStorage.put(blobs[resolution]);
      tasks.push(this.uploadAndGetUrl(task, resolution + 'W'));
    }

    return Promise.all(tasks).then(async (upladedImages) => {
      let defaultImage: any = {};
      let resolutionsUrls: any = {};

      for (const image of upladedImages) {
        if (image.resolution === 'default') {
          defaultImage = image;
        } else {
          resolutionsUrls[image.resolution] = image.url;
        }
      }
      defaultImage['resolutions'] = resolutionsUrls;
      defaultImage['path'] = path;

      return defaultImage;
    });
  }

  public async delete_directory(imagePath: string): Promise<void> {
    // WARNING!!  user very carefully could delete whatever folder
    const storage = getStorage();
    const directoryRef = ref(storage, imagePath);

    listAll(directoryRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          console.log(itemRef);
          deleteObject(itemRef);
        });
      })
      .catch((error) => {
        console.error('error al eliminar imagenes de cloud storage', error);
      });
  }
  public async deleteImage(imagePath: string): Promise<void> {
    const storageRef = this.storage.ref(imagePath);
    await storageRef.delete().subscribe( res => {
      console.log('eliminó imagen', res);
    });
  }

  private async uploadAndGetUrl(task: AngularFireUploadTask, resolution: string): Promise<ImgStorageData> {
    const snap = await task;
    const { fullPath, bucket, name } = snap.metadata;
    const storage = getStorage();
    const storageRef = ref(storage, fullPath);
    const url = await getDownloadURL(storageRef);
    const meta: ImgStorageData = {
      url,
      fullPath,
      bucket,
      name,
      resolution,
      path: '',
      resolutions: {},
    };

    return meta;
  }
}
