<div class="flex flex-column align-items-center p-1 surface-overlay border-round">
  <span class="font-bold text-2xl block mb-2 mt-4"> Esta es una funcionalidad premium </span>

  <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-5rem w-5rem">
    <i class="pi pi-question text-5xl"></i>
  </div>

  <br />

  <p class="mb-0"> Regalamos códigos en las publicaciones de redes sociales para probar algunas funcionalidades </p>

  <div class="flex gap-3">
    <button (click)="toSocialNetwork('tiktok')" pButton pRipple label="Tiktok" class="p-button-text p-0"></button>
    <button
      style="color: rgb(19, 131, 211)"
      (click)="toSocialNetwork('instagram')"
      pButton
      pRipple
      label="Instagram"
      class="p-button-text p-0"></button>

    <button
      style="color: rgb(211, 19, 48)"
      (click)="toSocialNetwork('youtube')"
      pButton
      pRipple
      label="Youtube"
      class="p-button-text p-0"></button>
  </div>

  <br />

  <p>Pero mejor aún tenemos una oferta increible para ti.</p>
  <br />

  <div style="text-align: center">
    <button (click)="openSalesLetter()" class="promo-button"> 🚀 Promoción Prelanzamineto 🎉 antes de 03/Nov/2024 ⏰🗓️</button>
  </div>

  <br />
</div>
