import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public messageService: any;

  constructor() {}

  public socialAlert(title: string, subtile: string = ''): void {
    try {
      this.messageService.add({
        key: 'social',
        severity: 'info',
        summary: title,
        detail: subtile,
        life: 6500,
      });
    } catch (error) {
      console.error('Error en ToastService.socialAlert');
    }
  }

  public success(title: string, subtile: string = ''): void {
    this.messageService.add({
      key: 'main-toast',
      severity: 'success',
      summary: title,
      detail: subtile,
      life: 4000,
    });
  }

  info(title: string, subtile: string): void {
    this.messageService.add({
      key: 'main-toast',
      severity: 'info',
      summary: title,
      detail: subtile,
      life: 4000,
    });
  }

  warn(title: string, subtile: string): void {
    this.messageService.add({
      key: 'main-toast',
      severity: 'warn',
      summary: title,
      detail: subtile,
      life: 4500,
    });
  }

  error(title: string, subtile: string): void {
    try {
      this.messageService.add({
        key: 'main-toast',
        severity: 'error',
        summary: title,
        detail: subtile,
        life: 4000,
      });
    } catch (error) {
      console.error('Error en ToastService.error');
    }
  }

  custom(title: string, subtile: string): void {
    // TODO: http://primefaces.org/primeng/toast
  }
}
