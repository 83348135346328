import { UserState } from '.';

import { createSelector, createFeatureSelector } from '@ngrx/store';

const featureUser = createFeatureSelector<Readonly<UserState>>('user');

export const getUser = createSelector(featureUser, (state: UserState) => state.user);

// TODO es posible suscribirme solo a un elemento del usuario
// export const getSettings = createSelector(featureUser, (state: UserState) => state.user.settings);

export const getIsLoaded = createSelector(featureUser, (state: UserState) => state.isLoaded);

export const getIsLoading = createSelector(featureUser, (state: UserState) => state.isLoading);

export const getMetadata = createSelector(featureUser, (state: UserState) => {
  return { isLoading: state.isLoading, isRehydrated: state.isRehydrated, isLoaded: state.isLoaded };
});
