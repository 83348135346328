import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, Route, UrlSegment } from '@angular/router';

import { fromEvent, Observable, of } from 'rxjs';
import { mergeMap, concatMap, tap, first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';

import { FirebaseAuthService } from '../core/firebase-auth.service';
import { UserActions, UserSelectors } from './store/user';
import { UserService } from './data-services/user.service';
import { StoreUtilService } from './store/store-util.service';
import { MobileService } from './system/mobile.service';
import { RouteNames } from './enums';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private logger: NGXLogger,
    private store: Store,
    private fbAuthService: FirebaseAuthService,
    private router: Router,
    private userService: UserService,
    private storeUtil: StoreUtilService,
    private mobileService: MobileService,
  ) {}

  offlineEvent = fromEvent(window, 'offline');

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const { isLoaded, isRehydrated, isLoading } = this.storeUtil.getSnapshot<any>(UserSelectors.getMetadata);

    // new estrategy if user exist on memory it can be and pass authentication. until conexion is reach.

    if (isRehydrated && !isLoaded) {
      if (!isLoading) {
        this.initFirstSubscription(state.url);
      }
      return of(true);
    }
    return this.isAuthAndLoaded$(state.url);
  }

  private initFirstSubscription(nextUrl): void {
    this.store.dispatch(UserActions.setIsLoading({ isloading: true }));
    this.isAuthAndLoaded$(nextUrl)
      .pipe(
        first(),
        tap(() => {
          this.store.dispatch(UserActions.setIsLoading({ isloading: false }));
        }),
      )
      .subscribe();
  }

  private isAuthAndLoaded$(nextUrl: string): Observable<boolean> {
    const isLoadedUser$ = this.store.select(UserSelectors.getIsLoaded);

    const isAuthAndLoaded$ = this.fbAuthService.authState$.pipe(
      concatMap((isAuth) => {
        if (!isAuth) {
          if (this.mobileService.isMobileRoute(nextUrl)) {
            this.router.navigateByUrl('m/auth/login');
          } else {
            this.router.navigateByUrl('auth/login');
          }
          return of(false);
        } else {
          // TODO: creo que is loaded lo saco syncrono para reducir complejidad y asi saco al obserbable.

          return isLoadedUser$.pipe(
            concatMap((isLoaded) => {
              this.logger.debug('Verificando datos cargados del usuario', isLoaded);
              if (isLoaded) {
                return of(true);
              } else {
                // TODO quiza aqui debería manejar una excepcion
                return this.userService.getUser$().pipe(
                  mergeMap((user) => {
                    // esta parte tengo que abstraerla para procesar al usuario una vez que tengo el objeto pero debe ser lo mismo en memoria que en storage i guess!
                    this.logger.debug('Cargando datos de usuario', user);
                    // TODO quiero cambiar intro taken a status
                    if (user?.frontendSteps?.introTaken == 'pending') {
                      if (this.mobileService.isNativeMobileOrMobileRoute()) {
                        this.router.navigateByUrl(`${RouteNames.Mobile}/${RouteNames.Intro}`);
                      } else {
                        this.router.navigateByUrl(RouteNames.Intro);
                      }

                      return of(false);
                    }

                    return of(true);
                  }),
                );
              }
            }),
          );
        }
      }),
    );

    return isAuthAndLoaded$;
  }

  getLoadedUserOrLoad() {
    return of(true);
  }

  // TODO no se que hacer aquí para mejorar el lazy loading. quizá abstaer los métodos e implementar algo para obtener el privilegio que requiere desde el token.
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // throw new Error('Method not implemented.');
    return of(true);
  }
}
