import { Routes } from '@angular/router';

import { AuthGuardService } from './core/auth-guard.service';
import { IsAuthGuardService } from './core/is-auth-guard.service';
import { IsMobileGuardService } from './core/is-mobile-guard.service';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./information/information-routing').then((m) => m.RoutesInfo),
    canActivate: [IsMobileGuardService],
  },
  // {
  //   path: 'm',
  //   loadComponent: () => import('./layout-ionic/layout-ionic.component').then((m) => m.IonicLayoutComponent),
  //   loadChildren: () => import('./pages/page.routing').then((m) => m.RoutesPage),
  //   canActivate: [AuthGuardService],
  // },
  {
    path: 'm/auth',
    loadComponent: () => import('./layout-ionic/ionic-page-wraper/ionic-page-wraper.component').then((m) => m.IonicPageWraperComponent),
    loadChildren: () => import('./auth/auth-routing').then((m) => m.RoutesAuth),
  },
  {
    path: 'm/welcome',
    loadComponent: () => import('./information/welcome/welcome.page').then((m) => m.WelcomePage),
  },
  // {
  //   path: 'm',
  //   loadComponent: () => import('./layout-ionic/ionic-page-wraper/ionic-page-wraper.component').then((m) => m.IonicPageWraperComponent),
  //   loadChildren: () => import('./information/information-routing').then((m) => m.RoutesInfo),
  // },
  {
    path: 'm',
    loadComponent: () => import('./layout-ionic/ionic-app/ionic-app.component').then((m) => m.IonicAppComponent),
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadComponent: () => import('./layout-ionic/ionic-layout/layout-ionic.component').then((m) => m.IonicLayoutComponent), // Make sure to specify the component name here
        loadChildren: () => import('./pages/page.routing').then((m) => m.RoutesPage),
      },
      {
        path: 'stack',
        loadComponent: () => import('./layout-ionic/stack-ionic/stack-ionic.component').then((m) => m.StackIonicComponent),
        loadChildren: () => import('./pages/page.routing').then((m) => m.RoutesPage),
      },
      // You can add more child routes here
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth-routing').then((m) => m.RoutesAuth),
  },

  {
    path: '',
    loadChildren: () => import('./layout/layout-routing').then((m) => m.RoutesLayout),
    canActivate: [AuthGuardService],
  },
];
