import { createAction, props } from '@ngrx/store';
import { EnglishVerb, ProgressWordEvaluation } from '../../classes';

export const loadVerbs = createAction('[Verbs] load verbs');

export const setInitialState = createAction('[Verbs] set initial state');

export const resetInitialState = createAction('[Verbs] reset initial state');

export const setIsLoading = createAction('[Verbs] set is loading', props<{ isloading: boolean }>());

export const loadVerbsSuccess = createAction('[Verbs] load verbs success', props<{ verbs: Array<EnglishVerb> }>());

export const updateVerbSuccess = createAction('[Verbs] update verb success', props<{ verb: EnglishVerb }>());

export const createVerbSuccess = createAction('[Verbs] create verb success', props<{ verb: EnglishVerb }>());

export const updateProgressWord = createAction(
  '[Verbs] update progress verb success',
  props<{ progress: Array<ProgressWordEvaluation> }>(),
);

export const deleteVerbSuccess = createAction('[Verbs] delete Verb sucess', props<{ word: string }>());

export const loadProductFailure = createAction('[Verbs] Load Product Failure', props<{ error: string }>());

export const updateVerbProperty = createAction(
  '[Verbs] update verb property',
  props<{ word: string; property: string; value: any }>(),
);
