import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { EnglishPhrase } from '../classes';
import { HttpService } from '../system/http.service';
import { PhraseActions, PhraseSelectors } from '../store/phrases';
import { PhrasesWebApi } from '../enums';
import { StoreUtilService } from '../store/store-util.service';
@Injectable({
  providedIn: 'root',
})
export class PhraseService {
  constructor(private storeUtilService: StoreUtilService, private store: Store, private httpService: HttpService) { }

  public getPhrases$(): Observable<Array<EnglishPhrase>> {
    return this.httpService.getObservable<Array<EnglishPhrase>>(PhrasesWebApi.getPhrases);
  }

  public async saveNewPhraseWithAutocomplete(phrase: string): Promise<EnglishPhrase> {
    const phraseSaved: EnglishPhrase = await this.httpService.postDataToService(PhrasesWebApi.savePhraseWithString, { phrase });
    this.store.dispatch(PhraseActions.createPhraseSuccess({ phrase: phraseSaved }));
    return phraseSaved;
  }

  // create or update a phrase, if id is null then is created.
  public async savePhrase(phrase: EnglishPhrase): Promise<EnglishPhrase> {
    const savedPhrase = await this.httpService.postDataToService(PhrasesWebApi.postPhrase, phrase);
    if (phrase.id) {
      this.store.dispatch(PhraseActions.updatePhraseSuccess({ phrase: savedPhrase }));
    } else {
      this.store.dispatch(PhraseActions.createPhraseSuccess({ phrase: savedPhrase }));
    }
    return phrase;
  }

  public async deletePhrase(id: string): Promise<void> {
    await this.httpService.deleteDataFromService(`${PhrasesWebApi.deletePhrase}/${id}`);
    this.store.dispatch(PhraseActions.deletePhraseSuccess({ id }));
  }

  public async editPhrase(phraseModified) {
    const phraseEdited: EnglishPhrase = await this.httpService.postDataToService(PhrasesWebApi.postPhrase,
      phraseModified

    );
    this.store.dispatch(PhraseActions.updatePhraseSuccess({ phrase: phraseEdited }));
    return phraseEdited;
  }

  public getVerbsSnapshot(): ReadonlyArray<EnglishPhrase> {
    return this.storeUtilService.getSnapshot<ReadonlyArray<EnglishPhrase>>(PhraseSelectors.getPhrases);
  }

  public dispatchNewPhrase(phrase: EnglishPhrase): void {
    this.store.dispatch(PhraseActions.createPhraseSuccess({ phrase }));
  }
}
