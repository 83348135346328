import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaymentApi } from '../enums';
import { FirebaseAuthService } from '../firebase-auth.service';
import { UserActions } from '../store/user';
import { HttpService } from '../system/http.service';
import { ToastService } from './toast.service';

export interface AppPricing {
  id: string;
  stripePriceId: string;
  months: number;
  priceDollar: number;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(
    private httpService: HttpService,
    private store: Store,
    private firebaseAuthService: FirebaseAuthService,
    private toastrService: ToastService,
  ) {}

  public makePayment(payLoad: any) {
    // return this.httpService.postObservable<PaymentIntent, any>(PaymentApi.makePaymentIntent, payLoad);
  }

  public activateSubscription(payLoad: any) {
    return this.httpService.postDataToService(payLoad, PaymentApi.ActivateSubscription);
  }

  public async getPromo(promo: string) {
    return await this.httpService.getDataFromService(`${PaymentApi.GetPromo}/${promo}`);
  }

  public async getPricing() {
    return await this.httpService.getDataFromService(PaymentApi.GetPricing);
  }

  public async getPricingByMonths(months: number) {
    return await this.httpService.getDataFromService(`${PaymentApi.GetPricing}/${months}`);
  }

  public async savePricing(pricing: AppPricing) {
    return this.httpService.postDataToService(PaymentApi.SavePricing, pricing);
  }
}
